import { AnimatePresence, motion } from 'framer-motion';
import React, { FC } from 'react';
import styled from 'styled-components';
import { tablet } from 'styles/breakpoints';
import ReactCountdown from 'react-countdown';

interface TimerProps {
  countdownDate: number;
}

interface BottomBannerProps {
  text?: string;
  countdownDate?: number;
}

const Timer: FC<TimerProps> = ({ countdownDate }) => {
  const renderer = ({
    formatted = {
      days: '00',
      hours: '00',
      minutes: '00',
      seconds: '00',
    },
  }) => (
    <>
      <p>{formatted.minutes}</p>
      <p>:</p>
      <p>{formatted.seconds}</p>
    </>
  );

  return <ReactCountdown date={countdownDate} renderer={renderer} />;
};

const BottomBanner: FC<BottomBannerProps> = ({ text, countdownDate }) => (
  <AnimatePresence>
    <Container
      initial={{
        y: 20,
      }}
      animate={{
        y: 0,
      }}
      exit={{
        y: 20,
      }}
      transition={{
        type: 'spring',
        stiffness: 300,
        damping: 50,
        mass: 2,
      }}
    >
      <ContentContainer>
        <Text>{text}</Text>
        <TimeContainer>
          <Timer countdownDate={countdownDate!} />
        </TimeContainer>
      </ContentContainer>
    </Container>
  </AnimatePresence>
);

export default BottomBanner;

const Container = styled(motion.div)`
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #f9c620;
  padding: 0.75rem 1rem;
  display: flex;
  justify-content: center;
  z-index: 999;
  /* height: 3.5rem; */
  /* box-shadow: 0px -2px 15px 0px rgba(0, 0, 0, 0.15); */

  @media ${tablet} {
    padding: 0.75rem 1rem;
  }
`;

const ContentContainer = styled.div`
  max-width: 28.75rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Text = styled.p`
  /* color: #fff; */
  color: #000;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.375rem;
`;

const TimeContainer = styled.div`
  display: flex;

  p {
    /* color: #fff; */
    color: #000;
    text-align: center;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5rem;
  }
`;
